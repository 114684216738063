import { Button, Card, Col, List, Row, Typography, Carousel } from "antd";
import {
  FaRegLightbulb,
  FaHome,
  FaBus,
  FaGlobe,
  FaLightbulb,
  FaGlobeAmericas,
  FaBinoculars,
  FaCertificate,
  FaPuzzlePiece,
  FaHandsHelping,
} from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

import MainLayout from "../Layout/MainLayout";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { contact_path, frontendUrl } from "../Config/Config";

const { Title, Paragraph } = Typography;

const StudentTourPage = () => (
  <>
    <MainLayout>
      <HeroSection />
      <ProgramOverviewSection />
      <ActivityCarousel />
      <ExclusionSection />
      <BannerSection />
      <RolesResponsibilitiesSection />
      <NextStepSection />
      <ExpectationsSection />
    </MainLayout>
  </>
);

export default StudentTourPage;

const ActivityCarousel = () => {
  const carouselRef = useRef(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [currentSlide, setCurrentSlide] = useState(0);

  const activities = [
    {
      title: "Leadership\n Workshops",
      text: "Participate in dynamic workshops designed to enhance your leadership abilities through interactive exercises focused on teamwork, conflict resolution, and real-world scenarios, allowing you to apply learned concepts effectively.",
      icon: <FaLightbulb className="icon" />,
      bgClass: "bg-primary",
    },
    {
      title: "Cultural\n Immersion",
      text: "Experience the richness of local culture through a series of tailored activities and community engagements that provide deep insights into cultural traditions, values, and everyday life in the host community.",
      icon: <FaGlobeAmericas className="icon" />,
      bgClass: "bg-primary-2",
    },
    {
      title: "Sightseeing\n Tours",
      text: "Embark on guided sightseeing tours that take you to iconic landmarks and natural wonders, providing opportunities to explore the historical context and cultural significance of each location while enriching your travel experience.",
      icon: <FaBinoculars className="icon" />,
      bgClass: "bg-primary-3",
    },
    {
      title: "Program\n Certification",
      text: "Upon successful completion of the program, you will receive a certificate that recognizes your achievements and personal growth, highlighting the skills and experiences gained during your journey with us.",
      icon: <FaCertificate className="icon" />,
      bgClass: "bg-primary",
    },
    {
      title: "Teacher\n Support",
      text: "Teachers accompanying the students will benefit from complimentary lodging and meals throughout the program, ensuring a comfortable experience that allows them to focus on supporting their students effectively.",
      icon: <FaHandsHelping className="icon" />,
      bgClass: "bg-primary-2",
    },
    {
      title: "Custom\n Activities",
      text: "Enhance your program by choosing from a variety of optional activities that cater to your specific interests, offering unique learning experiences that can be customized to enrich your journey and personal growth.",
      icon: <FaPuzzlePiece className="icon" />,
      bgClass: "bg-primary-3",
    },
  ];

  const slidesToShow = isMobile ? 1 : 3.2;

  // Update the current slide index on slide change
  const handleSlideChange = (current: number) => {
    setCurrentSlide(current);
  };

  // Navigate to previous and next slides with controls
  const handlePrev = () => {
    // @ts-ignore
    carouselRef.current?.prev();
  };

  const handleNext = () => {
    // @ts-ignore
    carouselRef.current?.next();
  };

  return (
    <Col className="container bg-primary-2 section">
      <div className="section-title">
        <Title level={2}>
          {" "}
          <span className="text-secondary"> PROGRAM </span>{" "}
          <span className="text-white">ACTIVITIES</span>
        </Title>
        <Paragraph className="text-white">
          Students will participate in
        </Paragraph>
      </div>
      <div className="carousel-container">
        <Button
          className="carousel-arrow left-arrow"
          type="primary"
          icon={<LeftOutlined />}
          onClick={handlePrev}
          disabled={currentSlide === 0} // Disable if at the start
        />
        <Carousel
          ref={carouselRef}
          dots={false}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          infinite={false}
          afterChange={handleSlideChange} // Update on slide change
        >
          {activities.map((activity, index) => (
            <div key={index} className="carousel-card">
              <Card>
                <div className={`icon-container ${activity.bgClass}`}>
                  {activity.icon}
                </div>
                <Title level={3}>{activity.title}</Title>
                <Paragraph className="card-description">
                  {activity.text}
                </Paragraph>
              </Card>
            </div>
          ))}
        </Carousel>
        <Button
          className="carousel-arrow right-arrow"
          type="primary"
          icon={<RightOutlined />}
          onClick={handleNext}
          disabled={currentSlide >= activities.length - slidesToShow} // Disable if at the end
        />
      </div>
    </Col>
  );
};

const RolesResponsibilitiesSection = () => {
  const ourResponsibilities = [
    "Design and distribute program itinerary and brochures.",
    "Coordinate onboarding events, bookings, and homestay placements.",
    "Organize transport, meals, and accommodation.",
    "Provide 24/7 local contact points and arrange visits to local attractions.",
    "Handle all bookings except flights, visas, and insurance.",
  ];

  const yourResponsibilities = [
    "Promote the program to students and assign point-of-contact staff members.",
    "Manage visa and flight arrangements and submit necessary documents.",
    "Ensure timely payments and submission of required forms.",
    "Collect and compile feedback from participants for continuous improvement.",
    "Coordinate with local authorities for necessary permits and approvals.",
  ];

  return (
    <Col className="section ">
      <div className="container px-md-5">
        <div className="section-title">
          <Title level={2}>
            {" "}
            <span className="text-secondary"> ROLES AND </span>
            RESPONSIBILITIES{" "}
          </Title>
          <Paragraph>
            The following roles and responsibilities outline our shared
            commitments
          </Paragraph>
        </div>

        <Row justify="center" gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card className="">
              <Title level={3} className="mb-3 ">
                Our Responsibilities
              </Title>
              <List
                bordered={false}
                dataSource={ourResponsibilities}
                renderItem={(item) => (
                  <List.Item className="">{item}</List.Item>
                )}
              />
            </Card>
          </Col>

          <Col xs={24} md={12} className="mt-4 mt-md-0">
            <Card className="">
              <Title level={3} className="mb-3 ">
                Your Responsibilities
              </Title>
              <List
                bordered={false}
                dataSource={yourResponsibilities}
                renderItem={(item) => (
                  <List.Item className=""> {item}</List.Item>
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

const expectationsContent = [
  {
    title: "Curriculum Development",
    description:
      "Design a comprehensive curriculum that promotes learning through engaging activities and experiences.",
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1729228639/girls-having-fun-sharing-book_h0gq2n.jpg",
  },
  {
    title: "Activity Coordination",
    description:
      "Facilitate sports and extracurricular activities to encourage teamwork and personal growth among students.",

    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1729228912/teen-girls-listening-friend-while-studying_ajoqrn.jpg",
  },
  {
    title: "Student Integration",
    description:
      "Assign local student buddies to provide guidance and support, ensuring a smoother transition for visiting students.",
    src: "https://res.cloudinary.com/dbgw9jgum/image/upload/v1729229468/three-serious-fellow-students-studying-reading-textbooks_50_xfxesg.webp",
  },
];

const ExpectationsSection = () => {
  return (
    <Col className="section">
      <div className="container px-md-5">
        <div className="section-title">
          <Title level={2}>
            {" "}
            <span className="text-secondary"> EXPECTATIONS FOR </span>
            HOSTING SCHOOLS{" "}
          </Title>
          <Paragraph>
            Outlined below are the key commitments we expect from our hosting
            schools
          </Paragraph>
        </div>

        <Row
          justify="center"
          gutter={[0, 0]}
          className="card-container text-left"
        >
          {expectationsContent.map((item, index) => (
            <Col xs={24} sm={8} className="card-column">
              <Card bordered={false} className="custom-card">
                <img
                  src={item.src}
                  alt="Australian hosting school"
                  style={{
                    width: "300px",
                    height: "300px",
                    borderRadius: "50%  50% 50% 0 ", // Make the image round
                  }}
                />
                <Title level={3}>{item.title}</Title>
                <Paragraph className="card-description">
                  {item.description}
                </Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Col>
  );
};

const HeroSection = () => {
  const downloadReport = (file: string, fileName: string) => {
    const link = document.createElement("a");
    link.href = `${frontendUrl}/${file}`;
    link.download = fileName;
    link.click();
  };
  return (
    <Col className="container hero">
      <Row justify="start" align="middle" gutter={[0, 16]}>
        <Col xs={24} md={15}>
          <Title level={1} style={{ color: "white" }}>
            Discover our Leadership and{" "}
            <span className="text-secondary"> Student Exchange Program</span>
          </Title>
          <Title level={5} className="text-white">
            Empowering students through immersive leadership programs, cultural
            experiences, and essential skill-building.
          </Title>
          <Row gutter={[16, 16]}>
            <Col>
              <Button
                type="primary"
                size="large"
                onClick={() => (window.location.href = contact_path)}
              >
                Contact Us
              </Button>
            </Col>

            <Col>
              <Button
                size="large"
                onClick={() =>
                  downloadReport(
                    "tour-Itinerary.pdf",
                    "Azkroflyz-tour-Itinerary.pdf"
                  )
                }
              >
                Download Tour Itinerary
              </Button>
            </Col>

            <Col>
              <Button
                size="large"
                onClick={() =>
                  downloadReport(
                    "student-exchange-program-FAQs.pdf",
                    "Azkroflyz-student-exchange-program-FAQs.pdf"
                  )
                }
              >
                FAQs
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={12}>
          {/* <img
        className={"image-container"}
        src="https://res.cloudinary.com/dbgw9jgum/image/upload/v1728937156/WhatsApp_Image_2025-10-15_at_01.48.23_pm6ch8.jpg"
        alt="student tour program"
      /> */}
        </Col>
      </Row>
    </Col>
  );
};

const ProgramOverviewSection = () => {
  return (
    <Col id="program-details " className="section container">
      <div className="section-title">
        <Title level={2}>
          {" "}
          <span className="text-secondary"> PROGRAM </span>OVERVIEW{" "}
        </Title>
        <Paragraph className="">The study tour program includes</Paragraph>
      </div>
      <Row
        justify="center"
        gutter={[0, 0]}
        className="card-container text-left"
      >
        <Col xs={24} sm={6} className="card-column">
          <Card bordered={false} className="custom-card">
            <div className="icon-container bg-primary">
              <FaRegLightbulb className="icon" />
            </div>
            <Title level={3}>Leadership Program</Title>
            <Paragraph className="card-description">
              A half-day workshop focusing on leadership, teamwork, and conflict
              resolution through hands-on activities and case studies.
            </Paragraph>
          </Card>
        </Col>

        <Col xs={24} sm={6} className="card-column">
          <Card bordered={false} className="custom-card">
            <div className="icon-container bg-primary-2">
              <FaHome className="icon" />
            </div>
            <Title level={3}>Accommodation</Title>
            <Paragraph className="card-description">
              Homestays with Australian families, or budget-friendly
              hotel/hostel options with daily meals provided.
            </Paragraph>
          </Card>
        </Col>

        <Col xs={24} sm={6} className="card-column">
          <Card bordered={false} className="custom-card">
            <div className="icon-container bg-primary-3">
              <FaBus className="icon" />
            </div>
            <Title level={3}>Transportation</Title>
            <Paragraph className="card-description">
              Managed airport pickups, along with transportation for all
              scheduled activities.
            </Paragraph>
          </Card>
        </Col>

        <Col xs={24} sm={6}>
          <Card bordered={false} className="custom-card">
            <div className="icon-container bg-primary">
              <FaGlobe className="icon" />
            </div>
            <Title level={3}>Cultural Immersion</Title>
            <Paragraph className="card-description">
              Five-day experiential learning module with activities, excursions,
              and a certificate of completion.
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

const ExclusionSection = () => {
  return (
    <Col id="exclusions" className="section">
      <div className="container text-center">
        <div className="section-title ">
          <Title level={2}>
            <span className="text-secondary"> WHAT’S </span>
            NOT INCLUDED
          </Title>
          <Paragraph className="lead mb-5">
            The following items are excluded from the program package
          </Paragraph>
        </div>

        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <Title level={3}>Flights & Visas</Title>
              <Paragraph className="card-description">
                Airfare for students and teachers is not covered, including any
                visa application fees and associated costs for entry into the
                host country.
              </Paragraph>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <Title level={3}>Insurance</Title>
              <Paragraph className="card-description">
                Travel and health insurance are the responsibility of
                participants, ensuring coverage for medical emergencies and
                unexpected events during the trip
              </Paragraph>
            </Card>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <Title level={3}>Personal Expenses</Title>
              <Paragraph className="card-description">
                Any personal spending during the trip, such as souvenirs,
                additional meals, or leisure activities, is not included in the
                package
              </Paragraph>
            </Card>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

const BannerSection = () => {
  return (
    <Col
      className="section container bg-primary-3"
      style={{ textAlign: "center" }}
    >
      <div className="">
        <Title level={2} className="section-title text-black">
          JOIN THE ADVENTURE!
        </Title>
        <Paragraph className="text-white">
          Expand your horizons with this unique cultural and leadership
          experience.
        </Paragraph>
      </div>

      <Button
        type="primary"
        size="large"
        onClick={() => (window.location.href = contact_path)}
      >
        Contact Us
      </Button>
    </Col>
  );
};

const NextStepSection = () => {
  return (
    <Col className="section container bg-primary-2">
      <div className="section-title">
        <Title level={2}>
          <span className="text-secondary"> NEXT </span>
          <span className="text-white"> STEPS</span>
        </Title>
        <Paragraph className="text-white">
          Crafting a transformative experience for students
        </Paragraph>
      </div>
      <div>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12}>
            <img
              className={"image-container"}
              src="https://res.cloudinary.com/dbgw9jgum/image/upload/v1729229716/people-working-together-medium-shot_50_ngo7ge.jpg"
              alt="student tour program"
            />
          </Col>
          <Col xs={24} md={12}>
            <Paragraph className="text-white">
              Our program is thoughtfully designed to cultivate cultural
              awareness and develop essential leadership skills in today’s
              interconnected world. We aim to provide students with immersive
              opportunities to engage in local community activities, allowing
              them to experience diverse cultures and traditions firsthand.
              Through workshops and collaborative projects, students will
              enhance their teamwork and critical thinking abilities, building
              the leadership qualities necessary for their future endeavors.
            </Paragraph>
            <Paragraph className="text-white">
              As they embark on this journey together, students will enrich
              their educational experience and prepare to thrive in a diverse
              global environment. This program encourages exploration of
              different viewpoints, deepening their understanding of global
              issues while fostering empathy and awareness of the world around
              them.
            </Paragraph>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
