import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../Pages/Home";
import {
  contact_path,
  home_path,
  student_tours_path,
  survey_path,
} from "../Config/Config";
import StudentTourPage from "../Pages/StudentTour";
import ContactPage from "../Pages/Contact";
import SurveyPage from "../Pages/Survey";

const AppRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={home_path}
          element={
            <>
              <HomePage />
            </>
          }
        />
        <Route
          path={student_tours_path}
          element={
            <>
              <StudentTourPage />
            </>
          }
        />

        <Route
          path={contact_path}
          element={
            <>
              <ContactPage />
            </>
          }
        />
        <Route
          path={survey_path}
          element={
            <>
              <SurveyPage />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
